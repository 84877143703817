import React from "react"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"
import { GraphicImages } from "../components/graphic-images"

function GraphicDesign() {
  const [currentImage, setCurrentImage] = React.useState(0);
  const [viewerIsOpen, setViewerIsOpen] = React.useState(false);

  const openLightbox = React.useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  
  return (
    <>
      <h1>Graphic Design</h1>
      <p className="lead">
        Here's a sample of my public work. <br />
        I fell in love with graphic design when I was a young boy and Photoshop CS2 had just released. 
        I had all the time in the world to play around and learn the ins and outs. 
        Now with less spare time, I still try to tinker and create new pieces.
      </p>
      <div className="gallery-image">
        <Gallery photos={GraphicImages} onClick={openLightbox} />
      </div>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={GraphicImages.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}

export default GraphicDesign
