export const GraphicImages = [
  {
    src: "graphic-design/aventador_galaxy.png",
    width: 1,
    height: 1,
  },
  {
    src: "graphic-design/am95.jpg",
    width: 1,
    height: 1,
  },
  {
    src: "/graphic-design/y3wip.jpg",
    width: 1,
    height: 1,
  },
  {
    src: "graphic-design/bart_swagson2.png",
    width: 1,
    height: 1,
  },
  {
    src: "graphic-design/trillhouse.png",
    width: 1,
    height: 1,
  },
  {
    src: "graphic-design/yungjimbo.jpg",
    width: 1,
    height: 1,
  },
  {
    src: "graphic-design/APANO.jpg",
    width: 1,
    height: 1,
  },
  {
    src: "graphic-design/dotz.png",
    width: 1,
    height: 1,
  },
  {
    src: "graphic-design/cse2017.png",
    width: 1,
    height: 1,
  },
  {
    src: "graphic-design/pulp.png",
    width: 1,
    height: 1,
  },
  {
    src: "graphic-design/gfxprojectinsta.jpg",
    width: 1,
    height: 1,
  },
  {
    src: "graphic-design/kanye.png",
    width: 1389,
    height: 773,
  },
  {
    src: "graphic-design/lines.jpg",
    width: 4,
    height: 5,
  },
]